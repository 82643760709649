
@tailwind base;
@tailwind components;
@tailwind utilities;
/* .logo{
  width:8rem;
} */



.dropdown img{
  width: 16px;
  cursor: pointer;
  margin: 0 20px;


}

@media screen and (max-width: 1200px) {
  .dropdown img{
    width: 16px;
    cursor: pointer;
    margin: 10px 20px;
  
  
  }
   
 }